const SERVER_URL = "https://terminal-backend.prod.skilltrade.ai/";
const EXTERNAL_SERVER_URL = "https://scale-link.prod.skilltrade.ai/rest/v2";
const key = "R!mP!3k_Q6Mw2ykgYK7XYXBpMo7Cv!T2D4PKBhVPqhAkUpsMj2";
const encryptionKey = "HnPC2ueFwKdcFoaLq7xhkDm3chGVfsFNdXN8a6PNFX7pQ3";
const environment = "PROD";
const SET_FORMAT = (amount) => {
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  });

  return formatter.format(amount);
};
module.exports = {
  SERVER_URL,
  SET_FORMAT,
  environment,
  key,
  encryptionKey,
  EXTERNAL_SERVER_URL
};